<template>
  <div class="page-content">
    <page-breadcrumb title="AI Tutor - Error Feedback Report" class="mb-2" />
    <form-generator :schema="filter_schema" :model="filter">
      <template #slot_overall_report>
          <div class="d-flex">
            <b-button class="d-block mr-2" variant="success" size="sm" @click="overall_display=true">Overall Report</b-button>
            <b-button class="d-block" variant="success" size="sm" @click="overall_display=false">Details Report</b-button>
          </div>
      </template>
    </form-generator>
    <data-table-ssr v-show="overall_display" id="item_list" ref="item_list"
      :get-list-fn="getErrorReport" :columns="error_report_fields"
    />
    <data-table-ssr v-show="!overall_display" id="detail_error_list" ref="detail_error_list"
      :get-list-fn="getDetailReport" :columns="detail_error_report_field"
    />
  </div>
</template>

<script>
import service from '../service'

const error_report_fields = [
  { label: 'Feedback', field: 'text' },
  { label: 'Number of feedback', field: 'count' },
  { label: '% / Total', field: 'percentage'}
];
const detail_error_report_field = [
{ label: 'Scenario', field: 'scenario_title' },
{ label: 'Working Position', field: 'position' },
{ label: 'Career Field', field: 'career_field' },
{ label: 'Reported Sentence', field: 'sentence_content' },
{ label: 'Feedback', field: 'feedback' },
{ label: 'Report time', field: 'createdAt', display_type: 'datetime'}
]
const filter_schema = [
  {cols: 2, fields: [{ label: 'Start Time', field: 'start_time', input_type:'datetime' }]},
  {cols: 2, fields: [{ label: 'End Time', field: 'end_time', input_type:'datetime'}]},
  {cols: 4, fields: [{ field: 'overall_report', input_type:'slot'}], style: 'display: flex; align-items: center; margin-top: 10px'},
]
export default {
  data(){
    return{
      error_report_fields,
      filter_schema,
      detail_error_report_field,
      filter: {
        start_time: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end_time: new Date()
      },
      overall_display: true
    }
  },
  watch: {
    filter: {
      handler() {
        this.$refs.item_list.getList();
        this.$refs.detail_error_list.getList();
      },
      deep: true,
    },
  },
  methods:{
    async getErrorReport({ limit, page }) {
      let query = this.filter;
      let response = await service.getErrorReport(
        {
          query: {start_time: query.start_time, end_time: query.end_time},
          limit, page
        }
      );
      let list = [];
      let total = 0
      if (response) {
        list = response.report;
        total= response.total;
      }
      return { list, total }
    },
    async getDetailReport({ limit, page }) {
      let query = this.filter;
      let response = await service.getDetailErrorReport({
        query: {start_time: query.start_time, end_time: query.end_time},
        limit, page
      });
      let list = [];
      let total = 0
      if (response) {
        list = response.report;
        total= response.total;
      }
      return { list, total }
    },
  }
}
</script>
