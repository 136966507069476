import api from '@/libs/axios'

export default {
  async getErrorReport(params = {}){
    let response = await api.get('/admin/ai-tutor-feedback/error-report', {
      params,
    })
    return response.data.data || null
  },
  async getDetailErrorReport(params = {}){
    let response = await api.get('/admin/ai-tutor-feedback/detail-error-report', {
      params,
    })
    return response.data.data || null
  }
}
